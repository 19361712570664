<template>
  <div>
    <banner
      v-if="property"
      :link="`/property/${uprn}/${lodgementId}`"
      link-icon="chevron-left"
      link-text="Property"
      :title="formatStreetName(property.addressBase)"
      :text="formatAddressWithoutStreetName(property.addressBase)"
    />
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pull">
            <div class="row">
              <div class="col">
                <h2 class="h3">Details</h2>
                <dl v-if="measure">
                  <dt v-if="measure.createdAt">Lodged Date</dt>
                  <dd v-if="measure.createdAt">
                    {{ $helpers.dateTimeString(measure.createdAt) }}
                  </dd>
                  <dt v-if="measure.isManualMeasureInput">IsManualMeasureInput</dt>
                  <dd v-if="measure.isManualMeasureInput">{{ measure.isManualMeasureInput }}</dd>
                  <dt v-if="measure.manualMeasureApprovalDt">ManualMeasureApprovalDt</dt>
                  <dd v-if="measure.manualMeasureApprovalDt">
                    {{ $helpers.dateString(measure.manualMeasureApprovalDt) }}
                  </dd>
                  <dt v-if="measure.manualMeasureApprovedBy">ManualMeasureApprovedBy</dt>
                  <dd v-if="measure.manualMeasureApprovedBy">
                    {{ measure.manualMeasureApprovedBy }}
                  </dd>
                  <dt v-if="measure.workType">Work Type</dt>
                  <dd v-if="measure.workType">{{ measure.workType }}</dd>
                  <dt v-if="measure.measureCategory">Category</dt>
                  <dd v-if="measure.measureCategory">{{ measure.measureCategory }}</dd>
                  <dt v-if="measure.measureType">Type</dt>
                  <dd v-if="measure.measureType">{{ measure.measureType }}</dd>

                  <dt v-if="measure.measureTypeOther">Other</dt>
                  <dd v-if="measure.measureTypeOther">{{ measure.measureTypeOther }}</dd>
                  <dt v-if="measure.standard">Standard</dt>
                  <dd v-if="measure.standard">{{ measure.standard }}</dd>
                  <dt v-if="measure.pasAnnex">Annex</dt>
                  <dd v-if="measure.pasAnnex">{{ measure.pasAnnex }}</dd>
                  <dt v-if="measure.installedDate">Installed Date</dt>
                  <dd v-if="measure.installedDate">
                    {{ $helpers.dateString(measure.installedDate) }}
                  </dd>
                  <dt v-if="measure.handoverDate">Handover Date</dt>
                  <dd v-if="measure.handoverDate">
                    {{ $helpers.dateString(measure.handoverDate) }}
                  </dd>
                  <dt v-if="measure.installerReferenceNumber">Installer Reference</dt>
                  <dd v-if="measure.installerReferenceNumber">
                    {{ measure.installerReferenceNumber }}
                  </dd>
                  <dt v-if="measure.installerName">Installer Name</dt>
                  <dd v-if="measure.installerName">{{ measure.installerName }}</dd>
                  <dt v-if="measure.installerPasCertificateNumber">PAS Certificate Number</dt>
                  <dd v-if="measure.installerPasCertificateNumber">
                    {{ measure.installerPasCertificateNumber }}
                  </dd>
                  <dt v-if="measure.operativeName">Operative Name</dt>
                  <dd v-if="measure.operativeName">{{ measure.operativeName }}</dd>
                  <dt v-if="measure.operativeCertificationReference">
                    Operative Certificate Reference
                  </dt>
                  <dd v-if="measure.operativeCertificationReference">
                    {{ measure.operativeCertificationReference }}
                  </dd>
                  <dt v-if="measure.umr">UMR</dt>
                  <dd v-if="measure.umr">{{ measure.umr }}</dd>
                  <dt v-if="measure.tradeDescription">Trade Description</dt>
                  <dd v-if="measure.tradeDescription">{{ measure.tradeDescription }}</dd>
                  <dt v-if="measure.subInstallerName">Sub-Installer Name</dt>
                  <dd v-if="measure.subInstallerName">{{ measure.subInstallerName }}</dd>
                  <dt v-if="measure.subInstallerTrustMarkLicenceNumber">Sub-Installer TMLN</dt>
                  <dd v-if="measure.subInstallerTrustMarkLicenceNumber">
                    {{ measure.subInstallerTrustMarkLicenceNumber }}
                  </dd>
                  <dt v-if="measure.measureLifetime">Lifetime Measure</dt>
                  <dd v-if="measure.measureLifetime">{{ measure.measureLifetime }}</dd>
                  <dt v-if="measure.has100PercentMeasureInstalled">100% Measure Intalled</dt>
                  <dd v-if="measure.has100PercentMeasureInstalled">
                    {{ measure.has100PercentMeasureInstalled }}
                  </dd>
                  <dt v-if="measure.financialProtectionCategory">Financial Protection Category</dt>
                  <dd v-if="measure.financialProtectionCategory">
                    {{ measure.financialProtectionCategory }}
                  </dd>
                  <dt v-if="measure.guaranteeName">Guarantee Name</dt>
                  <dd v-if="measure.guaranteeName">{{ measure.guaranteeName }}</dd>
                  <dt v-if="measure.guaranteeIssuedBy">Guarantee Issued By</dt>
                  <dd v-if="measure.guaranteeIssuedBy">{{ measure.guaranteeIssuedBy }}</dd>
                  <dt v-if="measure.guaranteeStartDate">Guarantee Start Date</dt>
                  <dd v-if="measure.guaranteeStartDate">
                    {{ $helpers.dateString(measure.guaranteeStartDate) }}
                  </dd>
                  <dt v-if="measure.guaranteeEndDate">Guarantee End Date</dt>
                  <dd v-if="measure.guaranteeEndDate">
                    {{ $helpers.dateString(measure.guaranteeEndDate) }}
                  </dd>
                  <dt v-if="measure.guaranteeTerm">Guarantee Term</dt>
                  <dd v-if="measure.guaranteeTerm">{{ measure.guaranteeTerm }}</dd>
                  <dt v-if="measure.guaranteePolicyReference">Guarantee Policy Reference</dt>
                  <dd v-if="measure.guaranteePolicyReference">
                    {{ measure.guaranteePolicyReference }}
                  </dd>
                  <dt v-if="measure.guaranteeCoverDuration">Guarantee Cover Duration</dt>
                  <dd v-if="measure.guaranteeCoverDuration">
                    {{ measure.guaranteeCoverDuration }}
                  </dd>
                  <dt v-if="measure.overallObligationPeriod">Overall Obligation Period</dt>
                  <dd v-if="measure.overallObligationPeriod">
                    {{ measure.overallObligationPeriod }}
                  </dd>
                  <dt v-if="measure.ecoSupplierReference">ECO Supplier Reference</dt>
                  <dd v-if="measure.ecoSupplierReference">{{ measure.ecoSupplierReference }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SEO from './SEO.vue';
import Banner from './Banner.vue';
import { formatStreetName, formatAddressWithoutStreetName } from '../helpers/address';

export default {
  name: 'PropertyPage',
  components: {
    //  SEO,
    Banner,
  },
  data() {
    return {
      measure: null,
      session: null,
      lodgement: null,
      lodgementId: this.$route.params.lodgementId,
      uprn: this.$route.params.uprn,
      measureId: this.$route.params.measureId,
    };
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated;
    },
    property() {
      return this.$store.state.homeOwner.property;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.property) {
      this.$store
        .dispatch('homeOwner/getProperty', this.uprn)
        .then((resp) => {
          this.extractMeasure(resp);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$router.push('/property-not-found');
            return;
          }
          this.errorMessage = err;
        });
    } else {
      this.extractMeasure(this.property);
    }
  },
  methods: {
    formatStreetName,
    formatAddressWithoutStreetName,
    extractMeasure(property) {
      const lodgements = property.lodgements.filter((x) => x.id === this.lodgementId);
      const firstLodgement = lodgements[0];
      this.lodgement = firstLodgement;
      const measures = this.lodgement.measures.filter((m) => m.id === this.measureId);
      const firstMeasure = measures[0];
      this.measure = firstMeasure;
    },
  },
};
</script>
